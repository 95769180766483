import { confusionHeaderKey, confusionHeaderValue, confusionKey } from "@/utils/confusion/key";
import CryptoJS from "crypto-js";

export const aesEncrypt = (plaintext: string) => {
  const keyHex = CryptoJS.enc.Utf8.parse(confusionKey);
  const ivHex = CryptoJS.enc.Utf8.parse("");
  const encrypted = CryptoJS.AES.encrypt(plaintext, keyHex, {
    iv: ivHex,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

export const aesDecrypt = (encoded: string) => {
  const keyHex = CryptoJS.enc.Utf8.parse(confusionKey);
  const ivHex = CryptoJS.enc.Utf8.parse("");
  const decrypted = CryptoJS.AES.decrypt(encoded, keyHex, {
    iv: ivHex,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const CheckHeader = (headers: Record<string, string>) => {
  return headers[confusionHeaderKey] == confusionHeaderValue;
};
