import { myCompany } from "@/services/user/api";
import type { DtoCompany } from "@/services/user/typings";
import { useCallback, useState } from "react";

export default function () {
  const [company, setCompany] = useState<DtoCompany>();
  const [loading, setLoading] = useState(false);

  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      const resp = await myCompany();
      if (resp.data?.companyInfo) {
        setCompany(resp.data.companyInfo);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);

  return {
    company,
    refresh,
    loading,
    setCompany,
  };
}
