import { request } from "@umijs/max";
import type { ResponseType } from "axios";
import type { ApiResp } from "./response";

export function post<RespT = any>(
  url: string,
  body: any = undefined,
  params: any = undefined,
  skipErrorHandler: boolean = false,
  responseType: ResponseType | undefined = undefined,
) {
  return request<ApiResp<RespT>>(url, {
    method: "POST",
    params: params || {},
    data: body || {},
    skipErrorHandler,
    responseType,
  });
}

export function get<RespT = any>(
  url: string,
  params: any = undefined,
  skipErrorHandler: boolean = false,
  responseType: ResponseType | undefined = undefined,
) {
  return request<ApiResp<RespT>>(url, {
    method: "GET",
    params: params || {},
    skipErrorHandler,
    responseType,
  });
}

export default {
  get,
  post,
};
