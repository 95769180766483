import menu from "./zh-CN/menu";
import notification from "./zh-CN/notification";
import order from "./zh-CN/order";
import payment from "./zh-CN/payment";
import product from "./zh-CN/product";
import pwa from "./zh-CN/pwa";
import user from "./zh-CN/user";

export default {
  "default.action": "操作",
  "default.notes": "备注",
  "default.status": "状态",
  "default.submit": "提交",
  "default.resubmit": "重新提交",

  "navBar.lang": "语言",
  "layout.user.link.help": "帮助",
  "layout.user.link.privacy": "隐私",
  "layout.user.link.terms": "条款",
  "app.copyright.produced": "Blidz出品",

  "confirm.OkBtn": "确认",
  "confirm.CancelBtn": "取消",
  "alert.CloseBtn": "关闭",

  ...menu,
  ...pwa,
  ...user,
  ...product,
  ...order,
  ...payment,
  ...notification,
};
