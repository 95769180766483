export default {
  "menu.welcome": "欢迎",
  "menu.list.company-management": "公司管理",
  "menu.list.store-management": "店铺管理",
  "menu.list.store-management.joined-stores": "我加入的店铺",
  "menu.list.store-management.all-stores": "所有店铺",
  "menu.list.store-management.store-funds": "店铺资金",
  "menu.list.user-management": "人员管理",
  "menu.list.user-management.all-users": "所有人员",
  "menu.account.center": "个人中心",
  "menu.account.settings": "个人设置",
  "menu.account.trigger": "触发报错",
  "menu.account.changePassword": "修改密码",
  "menu.account.myCompany": "我的公司",
  "menu.account.logout": "退出登录",
  "menu.order": "订单管理",
  "menu.payment": "账单管理",
  "menu.payment.line-items": "流水",
  "menu.payment.bill-list": "账单",
  "menu.product": "商品管理",
  "menu.product.list": "商品列表",
  "menu.order.detail": "订单详情",
  "menu.site-message": "站内信",
  "menu.payment.billDetail": "账单详情",
  "menu.app-settings": "应用设置",
  "menu.payment.receipt": "收据",
  "menu.customer": "C端后台",
  "menu.customer.customer-user": "用户管理",
  "menu.customer.customer-user-detail": "用户详情",
  "menu.customer.ban-customer-user": "用户封禁",
  "menu.customer.manual-order": "手动订单",
  "menu.customer.customer-order": "订单管理",
  "menu.customer.customer-video": "用户视频",
  "menu.customer.customer-video-audit": "视频审核",
  "menu.customer.paypal-record": "PayPal记录",
  "menu.customer.mass-payout": "批量Payout",
  "menu.customer.google-merchant-center": "Google Merchant Center",
  "menu.customer.stripe-flow": "Stripe Flow",
};
