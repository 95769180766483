export default {
  // 账单属性相关的公共词条
  "payment.storeId": "店铺Id",
  "payment.orderId": "订单Id",
  "payment.amount": "金额",
  "payment.action": "操作",
  "payment.notes": "说明",
  "payment.createTime": "创建时间",
  "payment.status": "状态",

  "payment.billAction.orderCreated": "订单成功创建",
  "payment.billAction.orderCanceledByCustomer": "用户主动取消",
  "payment.billAction.orderCanceledByMerchant": "商户取消",
  "payment.billAction.withdrew": "商户提现",
  "payment.billAction.wired": "Blidz打款给商户",

  "payment.withdrawStatus.applying": "申请中",
  "payment.withdrawStatus.paid": "已支付",
  "payment.withdrawStatus.refused": "拒绝",

  "payment.lineitem.billEvent": "账单事件",
  "payment.lineitem.balanceUpdate": "余额变动",
  "payment.lineitem.frozenUpdate": "冻结金额变动",
  "payment.lineitem.withdrawingUpdate": "提现中金额变动",
  "payment.lineitem.balance": "变动后余额",
  "payment.lineitem.frozen": "变动后冻结金额",
  "payment.lineitem.withdrawing": "变动后提现中金额",

  "payment.billEvent.frozen": "冻结",
  "payment.billEvent.canceledByCustomer": "用户取消（发货前）",
  "payment.billEvent.refundedByCustomer": "用户取消（发货后）",
  "payment.billEvent.canceledByMerchant": "商户取消（发货前）",
  "payment.billEvent.refundedByMerchant": "商户取消（发货后）",
  "payment.billEvent.eligible": "解冻",
  "payment.billEvent.paid": "打款",
  "payment.billEvent.billed": "出账",
  "payment.billEvent.orderInterception": "订单拦截",
  "payment.billEvent.systemReward": "系统奖励",
  "payment.billEvent.systemPunishment": "系统惩罚",
  "payment.billEvent.balanceCompensate": "余额补偿",
  "payment.billEvent.balanceDeduction": "余额扣除",
  "payment.billEvent.withdrawingCompensate": "提现中补偿",
  "payment.billEvent.withdrawingDeduction": "提现中扣除",

  // 页面相关的公共词条

  // 具体页面词条
  "payment.ui.storeAccount": "店铺资金",
  "payment.ui.total": "账户总额",
  "payment.ui.withdrawable": "可提现余额",
  "payment.ui.frozen": "冻结金额",
  "payment.ui.withdrawing": "提现中",
  "payment.ui.withdrawHistory": "提现记录",
  "payment.ui.lineItem": "收支记录",

  // Toast
};
