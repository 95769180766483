import { logout } from "@/services/user/api";
import { useI18nTag } from "@/utils/i18n";
import { LogoutOutlined } from "@ant-design/icons";
import { setAlpha } from "@ant-design/pro-components";
import { useEmotionCss } from "@ant-design/use-emotion-css";
import { history, useAccess, useModel } from "@umijs/max";
import { Avatar, Spin } from "antd";
import { stringify } from "querystring";
import type { MenuInfo } from "rc-menu/lib/interface";
import React, { useCallback } from "react";
import { flushSync } from "react-dom";
import HeaderDropdown from "../HeaderDropdown";

const Name = () => {
  const { initialState } = useModel("@@initialState");
  const { currentUser } = initialState || {};

  const nameClassName = useEmotionCss(({ token }) => {
    return {
      width: "70px",
      height: "48px",
      overflow: "hidden",
      lineHeight: "48px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      [`@media only screen and (max-width: ${token.screenMD}px)`]: {
        display: "none",
      },
    };
  });

  return <span className={`${nameClassName} anticon`}>{currentUser?.username}</span>;
};

const AvatarLogo = () => {
  const { initialState } = useModel("@@initialState");
  const { currentUser } = initialState || {};

  const avatarClassName = useEmotionCss(({ token }) => {
    return {
      marginRight: "8px",
      color: token.colorPrimary,
      verticalAlign: "top",
      background: setAlpha(token.colorBgContainer, 0.85),
      [`@media only screen and (max-width: ${token.screenMD}px)`]: {
        margin: 0,
      },
    };
  });

  if (currentUser?.avatarUrl) {
    return (
      <Avatar size="small" className={avatarClassName} src={currentUser?.avatarUrl} alt="avatar" />
    );
  } else {
    return null;
  }
};

const AvatarDropdown: React.FC = () => {
  const i = useI18nTag();
  /**
   * 退出登录，并且将当前的 url 保存
   */
  const loginOut = async () => {
    await logout();
    const { search, pathname } = window.location;
    const urlParams = new URL(window.location.href).searchParams;
    /** 此方法会跳转到 redirect 参数所在的位置 */
    const redirect = urlParams.get("redirect");
    // Note: There may be security issues, please note
    if (window.location.pathname !== "/user/login" && !redirect) {
      history.replace({
        pathname: "/user/login",
        search: stringify({
          redirect: pathname + search,
        }),
      });
    }
  };
  const actionClassName = useEmotionCss(({ token }) => {
    return {
      display: "flex",
      height: "48px",
      marginLeft: "auto",
      overflow: "hidden",
      alignItems: "center",
      padding: "0 8px",
      cursor: "pointer",
      borderRadius: token.borderRadius,
      "&:hover": {
        backgroundColor: token.colorBgTextHover,
      },
    };
  });
  const { initialState, setInitialState } = useModel("@@initialState");
  const access = useAccess();

  const onMenuClick = useCallback(
    (event: MenuInfo) => {
      const { key } = event;
      if (key === "logout") {
        flushSync(() => {
          setInitialState((s) => ({ ...s, currentUser: undefined }));
        });
        loginOut();
        return;
      }
      history.push(`/account/${key}`);
    },
    [setInitialState],
  );

  const loading = (
    <span className={actionClassName}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );

  if (!initialState) {
    return loading;
  }

  const { currentUser } = initialState;

  if (!currentUser || !currentUser.userId) {
    return loading;
  }

  const menuItems = [
    {
      key: "change_password",
      label: i`menu.account.changePassword`,
    },
    access.isCompanyOwner
      ? {
          key: "my_company",
          label: i`menu.account.myCompany`,
        }
      : null,
    {
      key: "logout",
      icon: <LogoutOutlined />,
      label: i`menu.account.logout`,
    },
  ];

  return (
    <HeaderDropdown
      menu={{
        selectedKeys: [],
        onClick: onMenuClick,
        items: menuItems,
      }}
    >
      <span className={actionClassName}>
        <AvatarLogo />
        <Name />
      </span>
    </HeaderDropdown>
  );
};

export default AvatarDropdown;
