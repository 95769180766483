import { ErrorShowType } from "@/services/response";

export enum UserError {
  INVITATION_TOKEN_EXPIRED = 5002016,
  INVITATION_TOKEN_INVALID = 5002015,
}

export const errorShowType: Record<number, ErrorShowType> = {
  5002105: ErrorShowType.SILENT,
};
