import Footer from "@/components/Footer";
import RightContent from "@/components/RightContent";
import {
  getStore,
  myInfo as myInfoApi,
  sessionLogin as sessionLoginApi,
} from "@/services/user/api";
import { UserRole, type DtoStore, type DtoUserBasicInfo } from "@/services/user/typings";
import { LinkOutlined } from "@ant-design/icons";
import type { Settings as LayoutSettings } from "@ant-design/pro-components";
import { history, Link, type RunTimeLayoutConfig } from "@umijs/max";
import Aegis from "aegis-web-sdk";
import Cookies from "js-cookie";
import defaultSettings from "../config/defaultSettings";
import { storeIdKey } from "./constants";
import { errorConfig } from "./requestErrorConfig";

const isDev = process.env.NODE_ENV === "development";
const loginPath = "/user/login";

const noRedirectPath = ["/accept_invitation", "/forget_password", "/reset_password"]; // 即使登录失败也不跳转到登录页

let appInitialized = false;

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: DtoUserBasicInfo;
  currentStore?: DtoStore;
  loading?: boolean;
}> {
  let currentUser: DtoUserBasicInfo | undefined;
  let currentStore: DtoStore | undefined;
  const layoutSetting = { ...defaultSettings };

  const fetchUserInfo = async () => {
    try {
      const resp = await myInfoApi();
      currentUser = resp?.data?.user;
    } catch (error) {}
    return undefined;
  };

  const sessionLogin = async () => {
    try {
      const resp = await sessionLoginApi();
      return resp;
    } catch (error) {
      if (!noRedirectPath.includes(history.location.pathname)) {
        history.push(loginPath);
      }
    }
    return undefined;
  };

  const appInit = async () => {
    if (REACT_APP_ENV == "production") {
      new Aegis({
        id: "bRe1dT6r08pY17lnbp", // 上报 id
        uin: "", // 用户唯一 ID（可选）
        reportApiSpeed: true, // 接口测速
        reportAssetSpeed: true, // 静态资源测速
        spa: true, // spa 应用页面跳转的时候开启 pv 计算
        hostUrl: "https://rumt-zh.com",
        delay: 3000,
        api: {
          // 腾讯前端监控模块，会自动查找API response的body里的“code”参数（注意，不是http status），并且默认code为0时为成功
          // 但是我们API成功时会返回200。所以这里需要做一下适配
          retCodeHandler(data: string) {
            try {
              const json_data = JSON.parse(data);
              return {
                isErr: json_data.code !== 200,
                code: json_data.code,
              };
            } catch (e) {
              return {
                isErr: true,
                code: 500,
              };
            }
          },
        },
      });
    }

    // 如果不是登录页面，执行
    if (window.location.pathname !== loginPath) {
      const resp = await sessionLogin();
      currentUser = resp?.data?.userInfo;
    }

    appInitialized = true;

    return currentUser;
  };

  if (appInitialized === false) {
    await appInit();
  } else {
    await fetchUserInfo();
  }

  if (currentUser === undefined) {
    Cookies.remove(storeIdKey);
  } else if (currentUser.userRole == UserRole.MERCHANT) {
    /*
      storeId的来源有两个：
      1. URL里的query参数
      2. Cookie里存的参数
    */
    const searchParams = new URLSearchParams(history.location.search);
    const storeIdInQuery = searchParams.get(storeIdKey);
    const storeId = storeIdInQuery ?? Cookies.get(storeIdKey);
    if (storeId !== undefined) {
      try {
        const resp = await getStore({ storeId }, true);
        currentStore = resp.data?.store;
      } catch {
        Cookies.remove(storeIdKey);
      }
    }
  }

  if (currentUser?.userRole == UserRole.MANAGER) {
    layoutSetting.title += " (Blidz管理员)";
  }
  return {
    settings: layoutSetting,
    currentUser,
    currentStore,
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState }) => {
  return {
    rightContentRender: () => <RightContent />,
    footerRender: () => <Footer />,
    onPageChange: () => {
      const { location } = history;
      // 如果没有登录，重定向到 login
      if (!initialState?.currentUser && location.pathname !== loginPath) {
        history.push(loginPath);
      }
    },
    layoutBgImgList: [
      {
        src: "https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/D2LWSqNny4sAAAAAAAAAAAAAFl94AQBr",
        left: 85,
        bottom: 100,
        height: "303px",
      },
      {
        src: "https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/C2TWRpJpiC0AAAAAAAAAAAAAFl94AQBr",
        bottom: -68,
        right: -45,
        height: "303px",
      },
      {
        src: "https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/F6vSTbj8KpYAAAAAAAAAAAAAFl94AQBr",
        bottom: 0,
        left: 0,
        width: "331px",
      },
    ],
    links: isDev
      ? [
          <Link key="openapi" to="/umi/plugin/openapi" target="_blank">
            <LinkOutlined />
            <span>OpenAPI 文档</span>
          </Link>,
        ]
      : [],

    ...initialState?.settings,
  };
};

/**
 * @name request 配置，可以配置错误处理
 * 它基于 axios 和 ahooks 的 useRequest 提供了一套统一的网络请求和错误处理方案。
 * @doc https://umijs.org/docs/max/request#配置
 */
export const request = {
  ...errorConfig,
};
