import { DefaultFooter } from "@ant-design/pro-components";
import { useIntl } from "@umijs/max";

const Footer: React.FC = () => {
  const intl = useIntl();

  const currentYear = new Date().getFullYear();

  return (
    <DefaultFooter
      style={{
        background: "none",
      }}
      copyright={`${currentYear} ${intl.formatMessage({ id: "app.copyright.produced" })}`}
    />
  );
};

export default Footer;
