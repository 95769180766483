import menu from "./en-US/menu";
import notification from "./en-US/notification";
import order from "./en-US/order";
import payment from "./en-US/payment";
import product from "./en-US/product";
import pwa from "./en-US/pwa";
import user from "./en-US/user";

export default {
  "default.action": "Action",
  "default.notes": "Notes",
  "default.status": "Status",
  "default.submit": "Submit",
  "default.resubmit": "Re-submit",

  "navBar.lang": "Languages",
  "layout.user.link.help": "Help",
  "layout.user.link.privacy": "Privacy",
  "layout.user.link.terms": "Terms",
  "app.copyright.produced": "Produced by Blidz",

  "confirm.OkBtn": "OK",
  "confirm.CancelBtn": "Cancel",
  "alert.CloseBtn": "Close",

  ...menu,
  ...pwa,
  ...user,
  ...product,
  ...order,
  ...payment,
  ...notification,
};
