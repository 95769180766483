import webapi from "@/services/webapi";
import type * as components from "./typings";
export * from "./typings";

/**
 * @description
 * @param params
 */
export function searchSiteMessages(params: components.SearchSiteMessagesReqParams) {
  return webapi.get<components.SearchSiteMessagesResp>(
    "/notification/site_message/v1/search",
    params,
  );
}

/**
 * @description
 * @param req
 */
export function readSiteMessage(req: components.ReadSiteMessageReq) {
  return webapi.post<components.ReadSiteMessageResp>("/notification/site_message/v1/read", req);
}

/**
 * @description
 * @param params
 */
export function unreadSiteMessagesCount(params: components.UnreadSiteMessagesCountReqParams) {
  return webapi.get<components.UnreadSiteMessagesCountResp>(
    "/notification/site_message/v1/unread_count",
    params,
  );
}
