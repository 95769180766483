import { errorShowType as orderError } from "@/services/order/errors";
import { errorShowType as userError } from "@/services/user/errors";

import { ErrorShowType } from "@/services/response";

export const commonErrorShowType: Record<number, ErrorShowType> = {
  5001001: ErrorShowType.ERROR_MESSAGE,
  5001002: ErrorShowType.WARN_MESSAGE,
  5001003: ErrorShowType.ERROR_MESSAGE,
  5001004: ErrorShowType.ERROR_MESSAGE,
  5001005: ErrorShowType.ERROR_MESSAGE,
};

export const allErrorShowType = {
  ...commonErrorShowType,
  ...userError,
  ...orderError,
};
