export default {
  // 订单属性相关的公共词条
  "order.id.tip": "BlidzMarketplaceOrderID",
  "order.cId": "Order Id",
  "order.customerEmail": "Customer Email",
  "order.totalAmount": "Total Amount",
  "order.totalPostage": "Total Postage",
  "order.payAmount": "Pay Amount",
  "order.currency": "Currency",
  "order.productId": "Product ID",
  "order.productName": "Product Name",
  "order.skuId": "SKU Id",
  "order.shippingOption": "Shipping Option",
  "order.productImage": "Product Image",
  "order.mpTrackingId": "Tracking ID",
  "order.status": "Status",
  "order.createTime": "Create Time",
  "order.shippedTime": "Shipped Time",
  "order.canceledTime": "Canceled Time",

  // 订单状态
  "order.status.failed": "Failed",
  "order.status.pending": "Pending",
  "order.status.shipped": "Shipped",
  "order.status.delivered": "Delivered",
  "order.status.received": "Received",
  "order.status.canceled": "Canceled",

  // 订单取消原因
  "order.cancelReason.others": "Others",
  "order.cancelReason.outOfStock": "Out Of Stock",
  "order.cancelReason.invalidPrice": "Invalid Price",
  "order.cancelReason.shippingAddressUnavailable": "Shipping Address Unavailable",

  // 结算状态
  "order.settlementStatus.statusNewCreated": "New Created",
  "order.settlementStatus.statusFrozen": "Frozen",
  "order.settlementStatus.statusCanceledByCustomer": "Canceled By Customer",
  "order.settlementStatus.statusRefundedByCustomer": "Refunded By Customer",
  "order.settlementStatus.statusCanceledByMerchant": "Canceled By Merchant",
  "order.settlementStatus.statusRefundedByMerchant": "Refunded By Merchant",
  "order.settlementStatus.statusEligible": "Eligible",
  "order.settlementStatus.statusPaid": "Paid",

  // 页面相关的公共词条
  "order.ui.operation": "Operation",
  "order.ui.showProduct": "Show Product",
  "order.ui.cancel": "Cancel",
  "order.ui.markshipped": "Mark shipped",
  "order.ui.getShippingInfo": "Get Shipping Info",
  "order.ui.downloadExcelToMarkShipped": "Download Mark Shipped Excel Template",

  // 具体页面词条
  "order.orderList.orderList": "OrderList",
  "order.orderList.batchCancelOrder": "BatchCancelOrder",

  "order.ShippingModal.title": "Shipping Form",
  "order.ShippingModal.carrier": "Carrier",
  "order.ShippingModal.carrier.placeholder": "Please input carrier",
  "order.ShippingModal.trackingNumber": "Tracking Number",
  "order.ShippingModal.trackingNumber.placeholder": "Please input the tracking number",

  "order.ApiResultModal.orderId": "Order ID",
  "order.ApiResultModal.errCode": "Error Code",
  "order.ApiResultModal.message": "Result",
  "order.ApiResultModal.title": "Operation Result",
  "order.ApiResultModal.close": "Close",
  "order.ApiResultModal.success": "All orders were successfully executed",

  "order.CancelOrderModal.title": "Cancel Order",
  "order.CancelOrderModal.submitText": "Save and cancel orders",
  "order.CancelOrderModal.resetText": "Close",
  "order.CancelOrderModal.cancelOrderIds": "The orders to be canceled:",
  "order.CancelOrderModal.reason": "Cancel Reason",
  "order.CancelOrderModal.notes": "Others, please add",

  "order.FileUploader.button": "Batch Marked Shipped With Excel",

  "order.OrderDetailDrawer.title": "Order Detail",

  "order.detail.basicInfo": "Basic Info",
  "order.detail.storeId": "Store Id",
  "order.detail.orderId": "Order Id",
  "order.detail.totalAmount": "Total Amount",
  "order.detail.orderStatus": "Order Status",
  "order.detail.productId": "Product Id",
  "order.detail.merchantProductId": "Merchant Product Id",
  "order.detail.skuId": "Sku Id",
  "order.detail.merchantSkuId": "Merchant Sku Id",
  "order.detail.productName": "Product Name",
  "order.detail.productImage": "Product Image",
  "order.detail.createTime": "Create Time",
  "order.detail.shippedTime": "Shipped Time",
  "order.detail.canceledTime": "Canceled Time",
  "order.detail.shippingAddress": "Shipping Address",
  "order.detail.recipient": "Recipient",
  "order.detail.phone": "Phone",
  "order.detail.zipCode": "Zip Code",
  "order.detail.countryCode": "Country Code",
  "order.detail.state": "State",
  "order.detail.city": "City",
  "order.detail.addressLine1": "Address Line1",
  "order.detail.addressLine2": "Address Line2",
  "order.detail.onlyShowToAdmin": "Only Show To Admin",
  "order.detail.payAmount": "Pay Amount",
  "order.detail.currency": "Currency",
  "order.detail.customerEmail": "Customer Email",
  "order.detail.settlementStatus": "Settlement Status",

  // Toast
  "order.msg.hasNoOrdersToCancel": "Has no orders to cancel",
  "order.msg.fileUploadFailed": "File upload failed",
};
