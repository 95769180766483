export default {
  // 订单属性相关的公共词条
  "order.id.tip": "Blidz Marketplace订单唯一Id",
  "order.cId": "订单ID",
  "order.customerEmail": "买家邮箱",
  "order.totalAmount": "订单金额",
  "order.totalPostage": "邮费",
  "order.payAmount": "实际支付金额",
  "order.currency": "买家支付货币单位",
  "order.productId": "商品Id",
  "order.productName": "商品名",
  "order.skuId": "SKU Id",
  "order.shippingOption": "邮寄选项",
  "order.productImage": "图片",
  "order.mpTrackingId": "物流Id",
  "order.status": "订单状态",
  "order.createTime": "创建时间",
  "order.shippedTime": "发货时间",
  "order.canceledTime": "取消时间",

  // 订单状态
  "order.status.failed": "下单失败",
  "order.status.pending": "待处理",
  "order.status.shipped": "已发货",
  "order.status.delivered": "已到达",
  "order.status.received": "已收货",
  "order.status.canceled": "已取消",

  // 订单取消原因
  "order.cancelReason.others": "其他",
  "order.cancelReason.outOfStock": "库存不足",
  "order.cancelReason.invalidPrice": "价格错误",
  "order.cancelReason.shippingAddressUnavailable": "下单地址不可达",

  // 结算状态
  "order.settlementStatus.statusNewCreated": "订单新创建",
  "order.settlementStatus.statusFrozen": "金额冻结中",
  "order.settlementStatus.statusCanceledByCustomer": "用户主动取消（未发货）",
  "order.settlementStatus.statusRefundedByCustomer": "用户主动取消（已发货）",
  "order.settlementStatus.statusCanceledByMerchant": "商户主动取消（未发货）",
  "order.settlementStatus.statusRefundedByMerchant": "商户主动取消（已发货）",
  "order.settlementStatus.statusEligible": "可提现",
  "order.settlementStatus.statusPaid": "已打款",

  // 页面相关的公共词条
  "order.ui.operation": "操作",
  "order.ui.showProduct": "查看商品",
  "order.ui.cancel": "取消",
  "order.ui.markshipped": "去发货",
  "order.ui.getShippingInfo": "查看物流信息",
  "order.ui.downloadExcelToMarkShipped": "下载Excel批量发货模板",

  // 具体页面词条
  "order.orderList.orderList": "订单列表",
  "order.orderList.batchCancelOrder": "批量取消订单",

  "order.ShippingModal.title": "物流表单",
  "order.ShippingModal.carrier": "物流公司",
  "order.ShippingModal.carrier.placeholder": "请输入物流公司",
  "order.ShippingModal.trackingNumber": "物流单号",
  "order.ShippingModal.trackingNumber.placeholder": "请输入物流单号",

  "order.ApiResultModal.orderId": "订单Id",
  "order.ApiResultModal.errCode": "错误码",
  "order.ApiResultModal.message": "结果",
  "order.ApiResultModal.title": "操作结果",
  "order.ApiResultModal.close": "关闭",
  "order.ApiResultModal.success": "所有订单操作成功",

  "order.CancelOrderModal.title": "取消订单",
  "order.CancelOrderModal.submitText": "保存，并取消订单",
  "order.CancelOrderModal.resetText": "关闭",
  "order.CancelOrderModal.cancelOrderIds": "要取消的订单：",
  "order.CancelOrderModal.reason": "取消原因",
  "order.CancelOrderModal.notes": "其他，请补充说明",

  "order.FileUploader.button": "Excel批量发货",

  "order.OrderDetailDrawer.title": "订单详情",

  "order.detail.basicInfo": "订单基础信息",
  "order.detail.storeId": "店铺Id",
  "order.detail.orderId": "订单Id",
  "order.detail.totalAmount": "订单金额",
  "order.detail.orderStatus": "订单状态",
  "order.detail.productId": "商品Id",
  "order.detail.merchantProductId": "商户商品Id",
  "order.detail.skuId": "skuId",
  "order.detail.merchantSkuId": "商户SkuId",
  "order.detail.productName": "商品名",
  "order.detail.productImage": "商品图",
  "order.detail.createTime": "创建时间",
  "order.detail.shippedTime": "发货时间",
  "order.detail.canceledTime": "取消时间",
  "order.detail.shippingAddress": "发货地址",
  "order.detail.recipient": "收件人",
  "order.detail.phone": "联系方式",
  "order.detail.zipCode": "邮编",
  "order.detail.countryCode": "国家码",
  "order.detail.state": "州",
  "order.detail.city": "市",
  "order.detail.addressLine1": "街道1",
  "order.detail.addressLine2": "街道2",
  "order.detail.onlyShowToAdmin": "以下内容仅Blidz管理员可见",
  "order.detail.payAmount": "支付金额",
  "order.detail.currency": "支付货币",
  "order.detail.customerEmail": "买家邮箱",
  "order.detail.settlementStatus": "结算状态",

  // Toast
  "order.msg.hasNoOrdersToCancel": "没有可取消的订单",
  "order.msg.fileUploadFailed": "文件上传失败",
};
