export default {
  // 账单属性相关的公共词条
  "payment.storeId": "Store Id",
  "payment.orderId": "Order Id",
  "payment.amount": "Amount",
  "payment.action": "Action",
  "payment.notes": "Notes",
  "payment.createTime": "Create Time",
  "payment.status": "Status",

  "payment.withdrawStatus.applying": "Applying",
  "payment.withdrawStatus.paid": "Paid",
  "payment.withdrawStatus.refused": "Refused",

  "payment.lineitem.billEvent": "Bill Event",
  "payment.lineitem.balanceUpdate": "Balance Update",
  "payment.lineitem.frozenUpdate": "Frozen Update",
  "payment.lineitem.withdrawingUpdate": "Withdrawing Update",
  "payment.lineitem.balance": "Balance",
  "payment.lineitem.frozen": "Frozen",
  "payment.lineitem.withdrawing": "Withdrawing",

  "payment.billEvent.frozen": "Frozen",
  "payment.billEvent.canceledByCustomer": "Canceled By Customer",
  "payment.billEvent.refundedByCustomer": "Refunded By Customer",
  "payment.billEvent.canceledByMerchant": "Canceled By Merchant",
  "payment.billEvent.refundedByMerchant": "Refunded By Merchant",
  "payment.billEvent.eligible": "Eligible",
  "payment.billEvent.paid": "Paid",
  "payment.billEvent.billed": "Billed",
  "payment.billEvent.orderInterception": "Order Interception",
  "payment.billEvent.systemReward": "System Reward",
  "payment.billEvent.systemPunishment": "System Punishment",
  "payment.billEvent.balanceCompensate": "Balance Compensate",
  "payment.billEvent.balanceDeduction": "Balance Deduction",
  "payment.billEvent.withdrawingCompensate": "Withdrawing Compensate",
  "payment.billEvent.withdrawingDeduction": "Withdrawing Deduction",

  // 页面相关的公共词条

  // 具体页面词条
  "payment.ui.storeAccount": "Store Account",
  "payment.ui.total": "Total",
  "payment.ui.withdrawable": "Withdrawable",
  "payment.ui.frozen": "Frozen",
  "payment.ui.withdrawing": "Withdrawing",
  "payment.ui.withdrawHistory": "Withdraw History",
  "payment.ui.lineItem": "Line Item",

  // Toast
};
