/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
import {
  CompanyRole,
  UserRole,
  type DtoStore,
  type DtoUserBasicInfo,
} from "@/services/user/typings";

export default function access(
  initialState: { currentStore?: DtoStore; currentUser?: DtoUserBasicInfo } | undefined,
) {
  const { currentUser, currentStore } = initialState ?? {};
  const isManager = currentUser?.userRole === UserRole.MANAGER;
  const isMerchant = currentUser?.userRole === UserRole.MERCHANT;
  const isCompanyOwner = currentUser?.roleInCompany === CompanyRole.OWNER;
  const isInStore = isMerchant && currentStore !== undefined;
  const isManagerOrInStore = isManager || isInStore;
  return {
    isManager, // Blidz管理员
    isMerchant, // 商户
    isInStore, // 已经进入店铺的商户
    isManagerOrInStore, // Blidz管理员，或已经进入店铺的商户
    isCompanyOwner, // 公司所有者
  };
}
