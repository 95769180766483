import webapi from "@/services/webapi";
import type * as components from "./typings";

//---------------------------------------------------
// 以下函数由backend生成
// `make ts API=user`
//---------------------------------------------------

//----- 以下函数在生成的代码上做了改动，如生成代码有变化，注意合并

/**
 * @description
 */
export function sessionLogin() {
  return webapi.post<components.LoginResp>(`/user/v1/session_login`, {}, undefined, true);
}

/**
 * @description
 * @param params
 */
export function getStore(params: components.GetStoreReqParams, skipErrorHandler: boolean = false) {
  return webapi.get<components.GetStoreResp>(`/user/v1/store`, params, skipErrorHandler);
}
/**
 * @description
 * @param params
 */
export function invitationInfo(
  params: components.InvitationInfoReqParams,
  skipErrorHandler: boolean = false,
) {
  return webapi.get<components.InvitationInfoResp>(
    `/user/v1/invitation_info`,
    params,
    skipErrorHandler,
  );
}
/**
 * @description
 * @param params
 * @param data
 */
export function uploadBusinessLicense(data: FormData) {
  return webapi.post<components.UploadBusinessLicenseResp>(
    `/user/v1/upload_business_license`,
    data,
  );
}

//----- 以下函数直接使用生成的代码
/**
 * @description
 * @param req
 */
export function emailLogin(req: components.EmailLoginReq) {
  return webapi.post<components.LoginResp>("/user/v1/email_login", req);
}

/**
 * @description
 * @param req
 */
export function phoneLogin(req: components.PhoneLoginReq) {
  return webapi.post<components.LoginResp>("/user/v1/phone_login", req);
}

/**
 * @description
 * @param req
 */
export function acceptInvitation(req: components.AcceptInvitationReq) {
  return webapi.post<components.LoginResp>("/user/v1/accept_invitation", req);
}

/**
 * @description
 * @param req
 */
export function forgetPassword(req: components.ForgetPasswordReq) {
  return webapi.post<null>("/user/v1/forget_password", req);
}

/**
 * @description
 * @param req
 */
export function resetPassword(req: components.ResetPasswordReq) {
  return webapi.post<null>("/user/v1/reset_password", req);
}

/**
 * @description
 */
export function myInfo() {
  return webapi.get<components.MyInfoResp>("/user/v1/me");
}

/**
 * @description
 */
export function logout() {
  return webapi.post<null>("/user/v1/logout");
}

/**
 * @description
 * @param req
 */
export function changePassword(req: components.ChangePasswordReq) {
  return webapi.post<null>("/user/v1/change_password", req);
}

/**
 * @description
 */
export function myCompany() {
  return webapi.get<components.GetCompanyResp>("/user/v1/my_company");
}

/**
 * @description
 * @param req
 */
export function joinStores(req: components.JoinStoresReq) {
  return webapi.post<components.JoinStoresResp>("/user/v1/join_stores", req);
}

/**
 * @description
 * @param params
 */
export function storesInMyCompany(params: components.StoresInMyCompanyReqParams) {
  return webapi.get<components.GetStoresResp>("/user/v1/stores_in_my_company", params);
}

/**
 * @description
 * @param params
 */
export function myStores(params: components.MyStoresReqParams) {
  return webapi.get<components.GetStoresResp>("/user/v1/my_stores", params);
}

/**
 * @description
 * @param params
 */
export function usersInMyCompany(params: components.UsersInMyCompanyReqParams) {
  return webapi.get<components.GetUsersResp>("/user/v1/users_in_my_company", params);
}

/**
 * @description
 * @param req
 */
export function inviteUserToMyCompany(req: components.InviteUserToMyCompanyReq) {
  return webapi.post<components.InviteUserResp>("/user/v1/invite_to_my_company", req);
}

/**
 * @description
 */
export function generateToken() {
  return webapi.post<components.GenerateTokenResp>("/user/user/v1/generate_token");
}

/**
 * @description
 */
export function refreshToken() {
  return webapi.post<components.RefreshTokenResp>("/user/user/v1/refresh_token");
}

/**
 * @description
 */
export function getToken() {
  return webapi.get<components.GetTokenResp>("/user/user/v1/get_token");
}

/**
 * @description
 */
export function getWebhookInfo() {
  return webapi.get<components.DtoWebhook>("/user/v1/webhook_info");
}

/**
 * @description
 * @param req
 */
export function updateWebhookUrl(req: components.UpdateWebhookUrlReq) {
  return webapi.post<null>("/user/v1/update_webhook_url", req);
}

/**
 * @description
 */
export function refreshWebhookSecret() {
  return webapi.post<components.RefreshWebhookSecretResp>("/user/v1/refresh_webhook_secret");
}

/**
 * @description
 */
export function getMyPaymentMethod() {
  return webapi.get<components.PaymentMethodResp>("/user/v1/my_payment_method");
}

/**
 * @description
 * @param req
 */
export function setPaymentMethod(req: components.SetPaymentMethodReq) {
  return webapi.post<null>("/user/v1/set_payment_method", req);
}

/**
 * @description
 */
export function getMyBusinessLicense() {
  return webapi.get<components.BusinessLicenseResp>("/user/v1/my_business_license");
}

/**
 * @description
 * @param req
 */
export function setBusinessLicense(req: components.SetBusinessLicenseReq) {
  return webapi.post<null>("/user/v1/set_business_license", req);
}

/**
 * @description
 * @param req
 */
export function createCompany(req: components.CreateCompanyReq) {
  return webapi.post<components.CreateCompanyResp>("/user/v1/create_company", req);
}

/**
 * @description
 * @param params
 */
export function searchCompanies(params: components.SearchCompaniesReqParams) {
  return webapi.get<components.SearchCompaniesResp>("/user/v1/search_companies", params);
}

/**
 * @description
 * @param req
 */
export function createStore(req: components.CreateStoreReq) {
  return webapi.post<components.CreateStoreResp>("/user/v1/create_store", req);
}

/**
 * @description
 * @param params
 */
export function searchStores(params: components.SearchStoresReqParams) {
  return webapi.get<components.GetStoresResp>("/user/v1/search_stores", params);
}

/**
 * @description
 * @param req
 */
export function logoutUser(req: components.LogoutUserReq) {
  return webapi.post<null>("/user/v1/logout_user", req);
}

/**
 * @description
 * @param params
 */
export function searchUsers(params: components.SearchUsersReqParams) {
  return webapi.get<components.GetUsersResp>("/user/v1/search_users", params);
}

/**
 * @description
 * @param params
 */
export function getPaymentMethod(params: components.GetPaymentMethodReqParams) {
  return webapi.get<components.PaymentMethodResp>("/user/v1/payment_method", params);
}

/**
 * @description
 * @param params
 */
export function getBusinessLicense(params: components.GetBusinessLicenseReqParams) {
  return webapi.get<components.BusinessLicenseResp>("/user/v1/business_license", params);
}

/**
 * @description
 * @param req
 */
export function inviteUser(req: components.InviteUserReq) {
  return webapi.post<components.InviteUserResp>("/user/v1/invite", req);
}

/**
 * @description
 * @param params
 */
export function storesOfUser(params: components.StoresOfUserReqParams) {
  return webapi.get<components.GetStoresResp>("/user/v1/stores_of_user", params);
}

/**
 * @description
 * @param req
 */
export function renewInvitationLink(req: components.RenewInvitationLinkReq) {
  return webapi.post<components.RenewInvitationLinkResp>("/user/v1/renew_invitation_link", req);
}
