export default {
  // common text about user property
  "user.storeId": "Store ID",
  "user.storeName": "Store Name",
  "user.userId": "User ID",
  "user.username": "Username",
  "user.phoneNumber": "Phone Number",
  "user.companyId": "Company ID",
  "user.companyName": "Company Name",
  "user.firstName": "First Name",
  "user.lastName": "Last Name",
  "user.fullName": "Full Name",
  "user.role": "Role",
  "user.roleInCompany": "Role in Company",
  "user.signupCompleteAt": "Signup Complete At",
  "user.role.merchant": "Merchant",
  "user.role.manager": "Blidz Manager",
  "user.status.pending": "Pending Signup",
  "user.status.active": "Active",
  "user.status.disabled": "Disabled",
  "user.companyRole.owner": "Owner",
  "user.companyRole.admin": "Admin",
  "user.companyRole.staff": "Staff",
  "user.paymentSupplier.paypal": "PayPal",
  "user.paymentSupplier.bank": "Bank",
  "user.paymentSupplier.payoneer": "Payoneer",
  "company.companyId": "Company ID",
  "company.accountManagerId": "Account Manager ID",
  "company.paymentMethod.supplier": "Payment Supplier",
  "company.paymentMethod.defaultSupplier": "Default Payment Supplier",
  "company.paymentMethod.paypal.account": "Account",
  "company.paymentMethod.bank.beneficiaryAccount": "Beneficiary Account",
  "company.paymentMethod.bank.beneficiaryName": "Beneficiary Name",
  "company.paymentMethod.bank.beneficiaryAddress": "Beneficiary Address",
  "company.paymentMethod.bank.bankName": "Beneficiary Bank Name",
  "company.paymentMethod.bank.bankCityAndState": "Bank City and State",
  "company.paymentMethod.bank.bankCountry": "Bank Country",
  "company.paymentMethod.bank.bankAbaOrSwift": "ABA or SWIFT Code",
  "company.paymentMethod.payoneer.account": "Account",
  "company.businessLicenseNumber": "Business License Number",
  "company.businessLicenseImage": "Business License Image",
  "company.paymentMethod.reminder": "Choose and fill at least one payment method.",
  "company.paymentMethod.warning.paypal":
    "The default payment method is selected, PayPal infomation required.",
  "company.paymentMethod.warning.bank":
    "The default payment method is selected, Bank infomation required.",
  "company.paymentMethod.warning.payoneer":
    "The default payment method is selected, Payoneer infomation required.",

  // common text about page display
  "user.ui.emailFormatError": "Email format is wrong",
  "user.ui.phoneNumberFormatError": "Phone number format is wrong",
  "user.ui.inviteUser": "Invite User",
  "user.ui.inviteToStoreTooltip":
    "If the store is specified, new user will join this store right after registation",
  "user.ui.invitationLinkNotGenerated": "Invitation link is not generated",
  "user.ui.businessLicenseImage": "Business License Image",
  "user.ui.paymentMethod": "Payment Method",
  "user.ui.businessLicense": "Business License",

  // text in certain page
  "pages.layouts.userLayout.title": "Blidz merchant dashboard",
  "pages.login.emailLogin.tab": "Account Login",
  "pages.login.success": "Login successful!",
  "pages.login.email.placeholder": "Email",
  "pages.login.password.placeholder": "Password",
  "pages.login.password.required": "Please input your password!",
  "pages.login.phoneLogin.tab": "Phone Login",
  "pages.login.phoneLogin.errorMessage": "Verification Code Error",
  "pages.login.phoneNumber.placeholder": "Phone Number",
  "pages.login.phoneNumber.required": "Please input your phone number!",
  "pages.login.captcha.placeholder": "Verification Code",
  "pages.login.captcha.required": "Please input verification code!",
  "pages.login.phoneLogin.getVerificationCode": "Get Code",
  "pages.getCaptchaSecondText": "sec(s)",
  "pages.login.rememberMe": "Remember me",
  "pages.login.forgotPassword": "Forgot Password ?",
  "pages.acceptInvitation.tokenEmpty": "Invitation token is empty",
  "pages.acceptInvitation.passwordDifferent": "The passwords are different",
  "pages.acceptInvitation.inviteYou": " invites you into Blidz platform",
  "pages.acceptInvitation.beenInvited": "You are invited into Blidz platform",
  "pages.acceptInvitation.invitationInfo": "Invitation Info",
  "pages.acceptInvitation.joinCompany": "Join Company",
  "pages.acceptInvitation.joinStore": "Join Store",
  "pages.acceptInvitation.setPassword": "Set Password",
  "pages.acceptInvitation.inputPassword": "Input Password",
  "pages.acceptInvitation.inputPasswordAgain": "Input Password Again",
  "pages.companyManagement.inviteMerchantToJoin": "Invite merchant to join",
  "pages.allStores.createStore": "Create Store",
  "pages.allStores.wire": "Wire Transfer",
  "pages.changePassword.lastPassword": "Last Password",
  "pages.changePassword.newPassword": "New Password",
  "pages.changePassword.changed": "Password changed",
  "pages.myCompany.changed": "Payment method changed",
  "pages.myCompany.noPaymentMethod": "Payment method not set",
  "pages.myCompany.setPaymentMethod": "Set Payment Method",
  "pages.myCompany.editPaymentMethod": "Edit Payment Method",
  "pages.myCompany.setBusinessLicense": "Set Business License",
  "pages.myCompany.editBusinessLicense": "Edit Business License",
  "pages.myCompany.noBusinessMethod": "Business License not set",
  "pages.forgotPassword.title": "Forget Password",
  "pages.forgotPassword.desc1": "Please provide your Email address",
  "pages.forgotPassword.desc2":
    "We will send you an Email. Click the link in it to reset your password",
  "pages.forgotPassword.desc3": "Check your trash box if the email is not in the inbox",
  "pages.resetPassword.title": "Reset Password",
  "pages.resetPassword.tokenEmpty": "Invitation token is empty",
  "pages.resetPassword.passwordDifferent": "The passwords are different",
  "pages.resetPassword.setPassword": "Set Password",
  "pages.resetPassword.inputPassword": "Input Password",
  "pages.resetPassword.inputPasswordAgain": "Input Password Again",
  "pages.resetPassword.succ": "Reset password succeeded",
  "components.managerWelcome.welcome": "Welcome!",
  "components.merchantWelcome.welcome": "Welcome!",
  "components.merchantWelcome.noCompany": "Not joined any company",
  "components.inviteModal.companyIdEmpty": "CompanyId is empty. Failed to create company",
  "components.inviteModal.storeIdEmpty": "StoreId is empty. Failed to create company",
  "components.inviteModal.createCompany": "Create Company",
  "components.inviteModal.createFirstStore": "Create First Store",
  "components.inviteModal.inviteMerchantToJoin": "Invite Merchant to Join",
  "components.createStoreModal.succeeded": "Create succeeded",
  "components.createStoreModal.createStore": "Create Store",
  "components.wiredModal.wireMoneyToMerchant": "Wire Money to Merchant",
  "components.wiredModal.storeBalance": "Store Balance",
  "components.wiredModal.total": "Total",
  "components.wiredModal.withdrawable": "Withdrawable",
  "components.wiredModal.frozen": "Frozen",
  "components.wiredModal.withdrawing": "Withdrawing",
  "components.wiredModal.amount": "Amount",
  "components.userDetail.basicInfo": "Basic Info",
  "components.userDetail.linkExpired": "Invitation link expired",
  "components.userDetail.linkInvalid": "Invitation link invalid",
  "components.userDetail.refreshLink": "Refresh invitation link",
  "components.userDetail.invitationInfo": "Invitation Info",
  "components.userDetail.inviter": "Inviter",
  "components.userDetail.invitationLink": "Invitation Link",
  "components.userDetail.linkExpireAt": "Expire At",
  "components.userDetail.joinedStores": "Joined Stores",
  "components.userDetail.userInfo": "User Info",
  "components.invitationLinkModal.success": "Invite succeeded",
  "components.invitationLinkModal.copyLink": "Copy this link and send to invitee",
  "components.merchantStores.enter": "Enter",
  "components.companyDetail.companyInfo": "Company Info",
  "components.companyDetail.basicInfo": "Basic Info",
  "components.companyDetail.noPaymentMethod": "Payment method not set",
  "components.companyDetail.noBusinessLicense": "Business license not set",
  "components.editBusinessLicense.imageRequired": "Business license image is required",
  "components.editBusinessLicense.changed": "Business license changed",
  "components.editBusinessLicenseModal.uploadImageFailed": "Upload image failed",
  "components.editBusinessLicenseModal.fileTooBig": "File is too big",
  "components.editBusinessLicenseModal.uploadLimit": "Max file size is 5MB",
  "components.editBusinessLicenseModal.businessLicenseNumberFormatError":
    "Business license number format invalid",
};
