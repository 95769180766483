export default {
  // 用户属性相关的公共词条
  "user.storeId": "店铺ID",
  "user.storeName": "店铺名称",
  "user.userId": "用户ID",
  "user.username": "用户名",
  "user.phoneNumber": "电话号码",
  "user.companyId": "公司ID",
  "user.companyName": "公司名",
  "user.firstName": "名",
  "user.lastName": "姓",
  "user.fullName": "姓名",
  "user.role": "角色",
  "user.roleInCompany": "公司中的角色",
  "user.signupCompleteAt": "注册完成于",
  "user.role.merchant": "商户",
  "user.role.manager": "Blidz管理员",
  "user.status.pending": "未完成注册",
  "user.status.active": "正常",
  "user.status.disabled": "已禁用",
  "user.companyRole.owner": "所有者",
  "user.companyRole.admin": "管理员",
  "user.companyRole.staff": "员工",
  "user.paymentSupplier.paypal": "PayPal",
  "user.paymentSupplier.bank": "银行",
  "user.paymentSupplier.payoneer": "Payoneer",
  "company.companyId": "公司ID",
  "company.accountManagerId": "客户经理ID",
  "company.paymentMethod.supplier": "支付服务商",
  "company.paymentMethod.defaultSupplier": "默认支付服务商",
  "company.paymentMethod.paypal.account": "账号",
  "company.paymentMethod.bank.beneficiaryAccount": "受益人账号",
  "company.paymentMethod.bank.beneficiaryName": "受益人名称",
  "company.paymentMethod.bank.beneficiaryAddress": "受益人地址",
  "company.paymentMethod.bank.bankName": "银行名称",
  "company.paymentMethod.bank.bankCityAndState": "银行所属城市和州",
  "company.paymentMethod.bank.bankCountry": "银行所属国家",
  "company.paymentMethod.bank.bankAbaOrSwift": "ABA/SWIFT编号",
  "company.paymentMethod.payoneer.account": "账号",
  "company.businessLicenseNumber": "统一社会信用代码",
  "company.businessLicenseImage": "营业执照图片",
  "company.paymentMethod.reminder": "至少选择并填满一种支付方式",
  "company.paymentMethod.warning.paypal": "选择了默认支付方式，必填PayPal信息",
  "company.paymentMethod.warning.bank": "选择了默认支付方式，必填银行信息",
  "company.paymentMethod.warning.payoneer": "选择了默认支付方式，必填Payoneer信息",

  // 页面内容相关的公共词条
  "user.ui.emailFormatError": "Email格式错误",
  "user.ui.phoneNumberFormatError": "手机号格式错误",
  "user.ui.inviteUser": "邀请人员",
  "user.ui.inviteToStoreTooltip": "如果指定店铺，则新人注册后直接加入该店铺",
  "user.ui.invitationLinkNotGenerated": "邀请链接未生成",
  "user.ui.businessLicenseImage": "营业执照图片",
  "user.ui.paymentMethod": "收款方式",
  "user.ui.businessLicense": "营业执照",

  // 具体页面的词条
  "pages.layouts.userLayout.title": "Blidz 商户平台",
  "pages.login.emailLogin.tab": "账户密码登录",
  "pages.login.success": "登录成功！",
  "pages.login.email.placeholder": "Email",
  "pages.login.password.placeholder": "密码",
  "pages.login.password.required": "密码是必填项！",
  "pages.login.phoneLogin.tab": "手机号登录",
  "pages.login.phoneLogin.errorMessage": "验证码错误",
  "pages.login.phoneNumber.placeholder": "请输入手机号！",
  "pages.login.phoneNumber.required": "手机号是必填项！",
  "pages.login.captcha.placeholder": "请输入验证码！",
  "pages.login.captcha.required": "验证码是必填项！",
  "pages.login.phoneLogin.getVerificationCode": "获取验证码",
  "pages.getCaptchaSecondText": "秒后重新获取",
  "pages.login.rememberMe": "自动登录",
  "pages.login.forgotPassword": "忘记密码?",
  "pages.acceptInvitation.tokenEmpty": "邀请token为空",
  "pages.acceptInvitation.passwordDifferent": "两次密码不一致",
  "pages.acceptInvitation.inviteYou": "邀请您加入Blidz平台",
  "pages.acceptInvitation.beenInvited": "邀请您加入Blidz平台",
  "pages.acceptInvitation.invitationInfo": "邀请信息",
  "pages.acceptInvitation.joinCompany": "加入公司",
  "pages.acceptInvitation.joinStore": "加入店铺",
  "pages.acceptInvitation.setPassword": "设置密码",
  "pages.acceptInvitation.inputPassword": "请输入密码",
  "pages.acceptInvitation.inputPasswordAgain": "请再次输入密码",
  "pages.companyManagement.inviteMerchantToJoin": "邀请商户入驻",
  "pages.allStores.createStore": "创建店铺",
  "pages.allStores.wire": "打款",
  "pages.changePassword.lastPassword": "原先密码",
  "pages.changePassword.newPassword": "新密码",
  "pages.changePassword.changed": "密码已修改",
  "pages.myCompany.changed": "收款方式已更改",
  "pages.myCompany.noPaymentMethod": "未设置收款方式",
  "pages.myCompany.setPaymentMethod": "设置收款方式",
  "pages.myCompany.editPaymentMethod": "编辑收款方式",
  "pages.myCompany.setBusinessLicense": "设置营业执照",
  "pages.myCompany.editBusinessLicense": "编辑营业执照",
  "pages.myCompany.noBusinessMethod": "未设置营业执照",
  "pages.forgotPassword.title": "忘记密码",
  "pages.forgotPassword.desc1": "请提供您的邮箱地址",
  "pages.forgotPassword.desc2": "我们会给您发送一封邮件，点击里面的链接来重置密码",
  "pages.forgotPassword.desc3": "如果收件箱里没有找到邮件，请尝试在垃圾邮件里查找",
  "pages.resetPassword.title": "重置密码",
  "pages.resetPassword.tokenEmpty": "邀请token为空",
  "pages.resetPassword.passwordDifferent": "两次密码不一致",
  "pages.resetPassword.setPassword": "设置密码",
  "pages.resetPassword.inputPassword": "请输入密码",
  "pages.resetPassword.inputPasswordAgain": "请再次输入密码",
  "pages.resetPassword.succ": "重置成功",
  "components.managerWelcome.welcome": "欢迎管理员",
  "components.merchantWelcome.welcome": "欢迎老板",
  "components.merchantWelcome.noCompany": "未加入公司",
  "components.inviteModal.companyIdEmpty": "CompanyId为空, 创建公司失败",
  "components.inviteModal.storeIdEmpty": "storeId为空, 创建公司失败",
  "components.inviteModal.createCompany": "创建公司",
  "components.inviteModal.createFirstStore": "创建首个店铺",
  "components.inviteModal.inviteMerchantToJoin": "邀请商户入驻",
  "components.createStoreModal.succeeded": "创建成功",
  "components.createStoreModal.createStore": "创建店铺",
  "components.wiredModal.wireMoneyToMerchant": "给商户打款",
  "components.wiredModal.storeBalance": "店铺资金",
  "components.wiredModal.total": "账户总额",
  "components.wiredModal.withdrawable": "可提现余额",
  "components.wiredModal.frozen": "冻结金额",
  "components.wiredModal.withdrawing": "提现中",
  "components.wiredModal.amount": "金额",
  "components.userDetail.basicInfo": "基本信息",
  "components.userDetail.linkExpired": "邀请链接已过期",
  "components.userDetail.linkInvalid": "邀请链接已失效",
  "components.userDetail.refreshLink": "刷新邀请链接",
  "components.userDetail.invitationInfo": "受邀信息",
  "components.userDetail.inviter": "邀请人",
  "components.userDetail.invitationLink": "邀请链接",
  "components.userDetail.linkExpireAt": "过期时间",
  "components.userDetail.joinedStores": "加入的店铺",
  "components.userDetail.userInfo": "用户信息",
  "components.invitationLinkModal.success": "邀请成功",
  "components.invitationLinkModal.copyLink": "将以下链接复制给被邀请人",
  "components.merchantStores.enter": "进入",
  "components.companyDetail.companyInfo": "公司信息",
  "components.companyDetail.basicInfo": "基本信息",
  "components.companyDetail.noPaymentMethod": "未设置支付方式",
  "components.companyDetail.noBusinessLicense": "未设置营业执照",
  "components.editBusinessLicense.imageRequired": "营业执照图片未上传",
  "components.editBusinessLicense.changed": "营业执照已修改",
  "components.editBusinessLicenseModal.uploadImageFailed": "上传图片失败",
  "components.editBusinessLicenseModal.fileTooBig": "图片文件过大",
  "components.editBusinessLicenseModal.uploadLimit": "图片最大5MB",
  "components.editBusinessLicenseModal.businessLicenseNumberFormatError":
    "统一社会信用代码格式错误",
};
