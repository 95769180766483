export default {
  // 产品属性相关的公共词条
  "product.merchantProductId": "商品ID",
  "product.merchantCategoryId": "店铺类目ID",
  "product.videoUrl": "视频地址",
  "product.productName": "商品名称",
  "product.merchantCategoryName": "店铺类目名称",
  "product.gtin": "GTIN标识",
  "product.mpn": "MPN标识",
  "product.brand": "Brand标识",
  "product.categories": "谷歌类目",
  "product.description": "描述",
  "product.images": "商品图片",
  "product.storeId": "所属店铺ID",
  "product.minPrice": "最低价格",
  "product.createdAt": "创建时间",
  "product.sku.name": "SKU名称",
  "product.sku.price": "SKU单价",
  "product.sku.stock": "SKU库存",
  "product.sku.image": "SKU图片",
  "product.sku.options": "属性",
  "product.sku.optionKey": "属性名",
  "product.sku.optionValue": "属性值",
  "product.status.pending": "待上架",
  "product.status.listed": "已上架",
  "product.status.delisted": "已下架",

  // 页面相关的公共词条
  "product.ui.imageIncorrect": "请正确上传图片",
  "product.ui.uploadImageFailed": "图片上传失败",
  "product.ui.update": "更新",
  "product.ui.add": "增加",
  "product.ui.cancel": "取消",
  "product.ui.close": "关闭",
  "product.ui.delete": "删除",
  "product.ui.productDetail": "商品详情",

  // 具体页面词条
  "page.productList.productIdEmpty": "ProductId为空",
  "page.productList.getProductFailed": "获取商品信息失败",
  "page.productList.actionFailed": "操作失败",
  "page.productList.listSucceeded": "上架成功",
  "page.productList.delistSucceeded": "下架成功",
  "page.productList.deleteSucceeded": "删除成功",
  "page.productList.deleteFailed": "删除失败",
  "page.productList.listProduct": "上架",
  "page.productList.delistProduct": "下架",
  "page.productList.confirmList": "确认上架这个商品？",
  "page.productList.confirmDelist": "确认下架这个商品？",
  "page.productList.confirmDelete": "删除商品后无法恢复，确认删除这个商品吗？",
  "page.productList.batchCreationSucceeded": "批量创建商品全部完成",
  "page.productList.fileUploadFailed": "文件上传失败",
  "page.productList.productList": "商品列表",
  "page.productList.downloadDoc": "下载商品批量上传使用说明",
  "page.productList.downloadExcelTpl": "下载excel模板",
  "page.productList.shortcutListProducts": "快捷上架",
  "page.productList.notExcel": "不是Excel文件",
  "page.productList.batchCreateProduct": "Excel批量创建商品",
  "page.productList.shotcutListProductsSuccess": "快捷上架商品处理成功，请耐心等待",
  "page.productList.shotcutListProductsTips": "将待上架的商品进行上架操作，每次最多10000条",
  "component.productDetailModal.updateFailed": "更新商品信息失败",
  "component.productDetailModal.updateSucceeded": "更新商品信息成功",
  "component.productDetailModal.editInfo": "编辑商品信息",
  "component.productDetailModal.uploadLimit": "最多上传10张图片，白底图片显示效果更好哦",
  "component.productDetailPanel.skuIdEmpty": "skuId为空, 不能删除操作",
  "component.productDetailPanel.deleteSkuFailed": "删除SKU操作失败",
  "component.productDetailPanel.deleteSkuSucceeded": "删除SKU成功",
  "component.productDetailPanel.editProductDetail": "编辑商品信息",
  "component.productDetailPanel.skuList": "SKU列表",
  "component.productDetailPanel.editSku": "编辑SKU信息",
  "component.productDetailPanel.newSku": "新增SKU",
  "component.skuDetailForm.updateSkuFailed": "更新SKU失败",
  "component.skuDetailForm.updateSkuSucceeded": "更新SKU成功",
  "component.skuDetailForm.addSkuFailed": "添加SKU失败",
  "component.skuDetailForm.addSkuSucceeded": "添加SKU成功",
  "component.skuDetailForm.deleteOption": "删除属性",
  "component.skuDetailPanel.editSku": "编辑SKU",
  "component.skuDetailPanel.confirmDelete": "确认删除这个SKU？",
  "component.skuDetailPanel.delete": "删除SKU",
  "component.batchCreateResultModal.errorLine": "错误行数",
  "component.batchCreateResultModal.errorMessage": "错误信息",
  "component.batchCreateResultModal.result": "批量创建结果",
  "component.batchCreateResultModal.note": "注：除以上商品创建失败之外，其余商品均创建成功。",
};
