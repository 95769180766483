export default {
  // 产品属性相关的公共词条
  "product.merchantProductId": "Product ID",
  "product.merchantCategoryId": "Merchant Category ID",
  "product.videoUrl": "Video URL",
  "product.productName": "Product Name",
  "product.merchantCategoryName": "Merchant Category Name",
  "product.gtin": "GTIN",
  "product.mpn": "MPN",
  "product.brand": "Brand",
  "product.categories": "Google Category",
  "product.description": "Description",
  "product.images": "Product Images",
  "product.storeId": "Store ID",
  "product.minPrice": "Min Price",
  "product.createdAt": "Created At",
  "product.sku.name": "SKU Name",
  "product.sku.price": "SKU Price",
  "product.sku.stock": "SKU Stock",
  "product.sku.image": "SKU Image",
  "product.sku.options": "Options",
  "product.sku.optionKey": "Option Name",
  "product.sku.optionValue": "Option Value",
  "product.status.pending": "Pending",
  "product.status.listed": "Listed",
  "product.status.delisted": "Delisted",

  // 页面相关的公共词条
  "product.ui.imageIncorrect": "Please upload image correctly",
  "product.ui.uploadImageFailed": "Image upload failed",
  "product.ui.update": "Update",
  "product.ui.add": "Add",
  "product.ui.cancel": "Cancel",
  "product.ui.close": "Close",
  "product.ui.delete": "Delete",
  "product.ui.productDetail": "Product Detail",

  // 具体页面词条
  "page.productList.productIdEmpty": "ProductId is empty",
  "page.productList.getProductFailed": "Get product info failed",
  "page.productList.actionFailed": "Action failed",
  "page.productList.listSucceeded": "List product succeeded",
  "page.productList.delistSucceeded": "Delist product succeeded",
  "page.productList.deleteSucceeded": "Delete succeeded",
  "page.productList.deleteFailed": "Delete failed",
  "page.productList.listProduct": "List Product",
  "page.productList.delistProduct": "Delist Product",
  "page.productList.confirmList": "Confirm to list this product?",
  "page.productList.confirmDelist": "Confirm to delist this product?",
  "page.productList.confirmDelete":
    "After deleting the product, it cannot be restored. Are you sure to delete this product?",
  "page.productList.batchCreationSucceeded": "Batch creation succeeded",
  "page.productList.fileUploadFailed": "File upload failed",
  "page.productList.productList": "Product List",
  "page.productList.downloadDoc": "Download description of create products",
  "page.productList.downloadExcelTpl": "Download Excel Template",
  "page.productList.shortcutListProducts": "Shortcut List Products",
  "page.productList.notExcel": " is not an excel file",
  "page.productList.batchCreateProduct": "Batch Create Product with Excel",
  "page.productList.shotcutListProductsSuccess": "Shotcut List Products Success. Please wait.",
  "page.productList.shotcutListProductsTips":
    "List the pending status products at most 10000 pieces each time",
  "component.productDetailModal.updateFailed": "Update product info failed",
  "component.productDetailModal.updateSucceeded": "Update product info succeeded",
  "component.productDetailModal.editInfo": "Edit Product Info",
  "component.productDetailModal.uploadLimit":
    "Upload 10 images at most. Images with white background looks better",
  "component.productDetailPanel.skuIdEmpty": "SkuId is empty, cannot delete",
  "component.productDetailPanel.deleteSkuFailed": "Delete SKU failed",
  "component.productDetailPanel.deleteSkuSucceeded": "Delete SKU succeeded",
  "component.productDetailPanel.editProductDetail": "Edit Product Detail",
  "component.productDetailPanel.skuList": "SKU List",
  "component.productDetailPanel.editSku": "Edit SKU Info",
  "component.productDetailPanel.newSku": "New SKU",
  "component.skuDetailForm.updateSkuFailed": "Update SKU failed",
  "component.skuDetailForm.updateSkuSucceeded": "Update SKU succeeded",
  "component.skuDetailForm.addSkuFailed": "Add SKU failed",
  "component.skuDetailForm.addSkuSucceeded": "Add SKU succeeded",
  "component.skuDetailForm.deleteOption": "Delete Option",
  "component.skuDetailPanel.editSku": "Edit SKU",
  "component.skuDetailPanel.confirmDelete": "Confirm to delete this SKU?",
  "component.skuDetailPanel.delete": "Delete SKU",
  "component.batchCreateResultModal.errorLine": "Error Line",
  "component.batchCreateResultModal.errorMessage": "Error Message",
  "component.batchCreateResultModal.result": "Batch Creation Result",
  "component.batchCreateResultModal.note":
    "PS: Only the above products are failed. All other products are succeeded",
};
