export const storeIdKey = "storeId";
export const sessionIdKey = "sessionId";
export const orderIdKey = "orderId";
export const billIdKey = "billId";
export const uploadFileArgName = "file";
export const productCreatExcelTplUrl =
  "https://mp-pro-1311877099.cos.ap-hongkong.myqcloud.com/excel/product_excel_template.xlsx";
export const orderMarkedShippedExcelTplUrl =
  "https://mp-dev-1311877099.cos.ap-hongkong.myqcloud.com/excel/%E6%89%B9%E9%87%8F%E5%8F%91%E8%B4%A7%E6%A8%A1%E7%89%88.xlsx";
export const fileTypeExcel = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const productCreateDoc =
  "https://mp-pro-1311877099.cos.ap-hongkong.myqcloud.com/excel/Blidz%E5%95%86%E6%88%B7%E5%B9%B3%E5%8F%B0%E5%95%86%E5%93%81%E7%AE%80%E8%A6%81%E8%AF%B4%E6%98%8E%E4%B9%A6.docx";
export const apiDoc =
  "https://merchant-blidz.feishu.cn/wiki/space/7249958569210888220?ccm_open_type=lark_wiki_spaceLink";
export const TimeSecond = 1000;
export const TimeMinute = TimeSecond * 60;
export const TimeHour = TimeMinute * 60;
export const TimeDay = TimeHour * 24;
