import { Settings as LayoutSettings } from "@ant-design/pro-components";

/**
 * @name
 */
const Settings: LayoutSettings & {
  pwa?: boolean;
  logo?: string;
} = {
  navTheme: "light",
  // 拂晓蓝
  colorPrimary: "#1890ff",
  layout: "mix",
  contentWidth: "Fluid",
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  title: "Blidz 商户平台",
  pwa: false,
  logo: "https://product-cdn-1311877099.cos.ap-hongkong.myqcloud.com/blidz-logo-icon.png",
  iconfontUrl: "",
};

export default Settings;
