import { useIntl } from "@umijs/max";
import { useCallback } from "react";

/**
 * 封装intl.formatMessage()函数，来简短代码。支持默认字符串
 *
 * ```
 * const i = useI18n()
 * console.log(i("pages.login.success"))
 * ```
 */
export function useI18n() {
  const intl = useIntl();
  const formatMessage = useCallback(
    (messageId: string, defaultMessage?: string) => {
      return intl.formatMessage({ id: messageId, defaultMessage });
    },
    [intl],
  );
  return formatMessage;
}

/**
 * 封装intl.formatMessage()函数，配合js的“带标签的模版字面值”功能使用，能简短代码并减少一层括号。不支持默认字符串
 *
 * ```
 * const i = useI18nTag()
 * console.log(i`pages.login.success`)
 * ```
 */
export function useI18nTag() {
  const intl = useIntl();
  const formatMessage = useCallback(
    (messageId: TemplateStringsArray) => {
      return intl.formatMessage({ id: messageId[0] });
    },
    [intl],
  );
  return formatMessage;
}
