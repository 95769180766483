/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/array-type */

export enum UserRole {
  MERCHANT = 1,
  MANAGER = 2,
}

export enum UserRoleStr {
  MERCHANT = "user.role.merchant",
  MANAGER = "user.role.manager",
}

export enum CompanyRole {
  OWNER = 1,
  ADMIN = 2,
  STAFF = 3,
}

export enum CompanyRoleStr {
  OWNER = "user.companyRole.owner",
  ADMIN = "user.companyRole.admin",
  STAFF = "user.companyRole.staff",
}

export enum AccountStatus {
  PENDING = 1,
  ACTIVE = 2,
  DISABLED = 3,
}

export enum AccountStatusStr {
  PENDING = "user.status.pending",
  ACTIVE = "user.status.active",
  DISABLED = "user.status.disabled",
}

export enum PaymentSupplier {
  PAYPAL = 1,
  BANK = 2,
  // PAYONEER = 3,
}

export const PaymentSupplierStr = {
  [PaymentSupplier.PAYPAL]: "user.paymentSupplier.paypal",
  [PaymentSupplier.BANK]: "user.paymentSupplier.bank",
  // [PaymentSupplier.PAYONEER]: "user.paymentSupplier.payoneer",
};

//---------------------------------------------------
// 以下内容由backend生成
// `make ts-bff API=user`
//---------------------------------------------------

export interface DtoUserBasicInfo {
  userId: string;
  createAt: number;
  status: number;
  email: string;
  username: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  avatarUrl: string;
  userRole: number;
  companyId: string;
  roleInCompany: number;
  signupCompleteTime: number;
}

export interface DtoCompany {
  companyId: string;
  companyName: string;
  companyLogoUrl: string;
  accountManagerId: string;
}

export interface DtoStore {
  storeId: string;
  companyId: string;
  name: string;
  logoUrl: string;
  createdUserId: string;
}

export interface DtoFailedJoinStore {
  storeId: string;
  errorCode: number;
  errorMsg: string;
}

export interface InviteUserReq {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  role: number;
  // 如果是邀请商户入驻，需要填写公司信息
  companyId?: string;
  roleInCompany?: number;
  // 如果邀请商户入驻，且填了StoreId，则直接加入该店铺
  storeId?: string;
}

export interface InviteUserToMyCompanyReq {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  roleInCompany: number;
  // 如果填了StoreId，则直接加入该店铺
  storeId?: string;
}

export interface InviteUserResp {
  user: DtoUserBasicInfo;
  invitationLink: string;
}

export interface EmailLoginReq {
  email: string;
  password: string;
}

export interface PhoneLoginReq {
  phoneNumber: string;
  password: string;
}

export interface AcceptInvitationReq {
  invitationToken: string;
  password: string;
}

export interface MyInfoResp {
  user: DtoUserBasicInfo;
}

export interface LoginResp {
  sessionId: string;
  userInfo: DtoUserBasicInfo;
}

export interface LogoutUserReq {
  userId: string;
}

export interface CreateCompanyReq {
  companyName: string;
  companyLogoUrl?: string;
}

export interface CreateCompanyResp {
  companyInfo: DtoCompany;
}

export interface GetCompanyResp {
  companyInfo: DtoCompany;
}

export interface CreateStoreReq {
  companyId: string;
  name: string;
  logoUrl?: string;
}

export interface CreateStoreResp {
  store: DtoStore;
}

export interface SearchCompaniesReq {}

export interface SearchCompaniesReqParams {
  companyId: string;
  userId: string;
  pageSize: number; // 一页项目数
  current: number; // 当前页码，从1开始
}

export interface SearchCompaniesResp {
  companies: Array<DtoCompany>;
  total: number; // 总数
}

export interface JoinStoresReq {
  staffId: string;
  storeIds: Array<string>;
}

export interface JoinStoresResp {
  failedStores: Array<DtoFailedJoinStore>;
}

export interface GetStoreReq {}

export interface GetStoreReqParams {
  storeId: string;
}

export interface GetStoreResp {
  store: DtoStore;
}

export interface SearchStoresReq {}

export interface SearchStoresReqParams {
  companyId: string;
  pageSize: number; // 一页项目数
  current: number; // 当前页码，从1开始
}

export interface StoresInMyCompanyReq {}

export interface StoresInMyCompanyReqParams {
  pageSize: number; // 一页项目数
  current: number; // 当前页码，从1开始
}

export interface StoresOfUserReq {}

export interface StoresOfUserReqParams {
  userId: string;
  pageSize: number; // 一页项目数
  current: number; // 当前页码，从1开始
}

export interface MyStoresReq {}

export interface MyStoresReqParams {
  pageSize: number; // 一页项目数
  current: number; // 当前页码，从1开始
}

export interface GetStoresResp {
  stores: Array<DtoStore>;
  total: number; // 总数
}

export interface UsersInMyCompanyReq {}

export interface UsersInMyCompanyReqParams {
  userId: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  status: number;
  phoneNumber: string;
  inviterId: string;
  signupCompleteTimeBegin: number;
  signupCompleteTimeEnd: number;
  pageSize: number; // 一页项目数
  current: number; // 当前页码，从1开始
}

export interface SearchUsersReq {}

export interface SearchUsersReqParams {
  userId: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  status: number;
  userRole: number;
  companyId: string;
  phoneNumber: string;
  inviterId: string;
  storeId: string;
  signupCompleteTimeBegin: number;
  signupCompleteTimeEnd: number;
  pageSize: number; // 一页项目数
  current: number; // 当前页码，从1开始
}

export interface GetUsersResp {
  users: Array<DtoUserBasicInfo>;
  total: number; // 总数
}

export interface InvitationInfoReq {}

export interface InvitationInfoReqParams {
  userId: string;
}

export interface InvitationInfoResp {
  inviterName: string;
  invitationLink: string;
  expireAt: number;
}

export interface RenewInvitationLinkReq {
  userId: string;
}

export interface RenewInvitationLinkResp {
  inviterName: string;
  invitationLink: string;
  expireAt: number;
}

export interface ChangePasswordReq {
  newPassword: string;
  lastPassword: string;
}

export interface PaymentMethodPaypal {
  account: string;
}

export interface PaymentMethodBank {
  beneficiaryAccount: string;
  beneficiaryName: string;
  beneficiaryAddress: string;
  bankName: string;
  bankCityAndState: string;
  bankCountry: string;
  bankAbaOrSwift: string;
}

export interface PaymentMethodPayoneer {
  account: string;
}

export interface SetPaymentMethodReq {
  defaultSupplier: number;
  paypal?: PaymentMethodPaypal;
  bank?: PaymentMethodBank;
  payoneer?: PaymentMethodPayoneer;
}

export interface DtoPaymentMethod {
  companyId: string;
  defaultSupplier: number;
  paypal?: PaymentMethodPaypal;
  bank?: PaymentMethodBank;
  payoneer?: PaymentMethodPayoneer;
}

export interface PaymentMethodResp {
  paymentMethod: DtoPaymentMethod;
}

export interface GetPaymentMethodReq {}

export interface GetPaymentMethodReqParams {
  companyId: string;
}

export interface UploadBusinessLicenseResp {
  url: string;
}

export interface GetBusinessLicenseReq {}

export interface GetBusinessLicenseReqParams {
  companyId: string;
}

export interface BusinessLicenseResp {
  businessLicenseNumber: string;
  businessLicenseUrl: string;
}

export interface SetBusinessLicenseReq {
  businessLicenseNumber: string;
  businessLicenseUrl: string;
}

export interface ForgetPasswordReq {
  // TODO: captcha
  email: string;
}

export interface ResetPasswordReq {
  resetPasswordToken: string;
  newPassword: string;
}

export interface DtoToken {
  accessToken: string;
  accessTokenExpiresAt: number;
  refreshToken: string;
  refreshTokenExpiresAt: number;
}

export interface GenerateTokenResp {
  token: DtoToken;
}

export interface RefreshTokenResp {
  accessToken: string;
  accessTokenExpiresAt: number;
}

export interface GetTokenResp {
  token: DtoToken;
}

export interface DtoWebhook {
  webhookUrl: string;
  webhookSecret: string;
}

export interface UpdateWebhookUrlReq {
  webhookUrl: string;
}

export interface RefreshWebhookSecretResp {
  webhookSecret: string;
}
