import { allErrorShowType } from "@/services/allErrorShowType";
import { ErrorShowType, type ApiResp } from "@/services/response";
import { aesDecrypt, aesEncrypt, CheckHeader } from "@/utils/confusion/aes";
import { confusionHeaderKey, confusionHeaderValue } from "@/utils/confusion/key";
import type { RequestOptions } from "@@/plugin-request/request";
import type { RequestConfig } from "@umijs/max";
import { message, notification } from "antd";
import Cookies from "js-cookie";
import { sessionIdKey } from "./constants";

const pathJoin = (paths: string[]) => {
  const separator = "/";
  const parts = paths.map((part, index) => {
    let newPart = "";
    if (index) {
      newPart = part.replace(new RegExp("^" + separator), "");
    }
    if (index !== paths.length - 1) {
      newPart = part.replace(new RegExp(separator + "$"), "");
    }
    return newPart;
  });
  return parts.join(separator);
};

/**
 * @name 错误处理
 * pro 自带的错误处理， 可以在这里做自己的改动
 * @doc https://umijs.org/docs/max/request#配置
 */
export const errorConfig: RequestConfig = {
  // 错误处理： umi@3 的错误处理方案。
  errorConfig: {
    // 错误接收及处理
    errorHandler: (error: any, opts: any) => {
      if (opts?.skipErrorHandler) throw error;
      // 我们的 errorThrower 抛出的错误。
      if (error.name === "BizError") {
        const errorInfo: ApiResp<any> = error.info;
        const { msg, code } = errorInfo;
        if (code in allErrorShowType) {
          switch (allErrorShowType[code]) {
            case ErrorShowType.SILENT:
              // do nothing
              break;
            case ErrorShowType.WARN_MESSAGE:
              message.warning(msg);
              break;
            case ErrorShowType.ERROR_MESSAGE:
              message.error(msg);
              break;
            case ErrorShowType.NOTIFICATION:
              notification.open({
                description: msg,
                message: code,
              });
              break;
            case ErrorShowType.REDIRECT:
              // TODO: redirect
              break;
            default:
              message.error(msg);
          }
        } else {
          message.warning(msg);
        }
      } else if (error.response) {
        // Axios 的错误
        // 请求成功发出且服务器也响应了状态码，但状态代码超出了 2xx 的范围
        message.error(`Response status:${error.response.status}`);
      } else if (error.request) {
        // 请求已经成功发起，但没有收到响应
        // \`error.request\` 在浏览器中是 XMLHttpRequest 的实例，
        // 而在node.js中是 http.ClientRequest 的实例
        message.error("None response! Please retry.");
      } else {
        // 发送请求时出了点问题
        message.error("Request error, please retry.");
      }
    },
  },

  // 请求拦截器
  requestInterceptors: [
    (config: RequestOptions) => {
      let url = config.url || "";

      const headers = config.headers || {};

      if (BFF !== null && BFF != "") {
        url = pathJoin([BFF, url]);
      }

      if (X_TAG && X_TAG != "") {
        headers["x-tag"] = X_TAG;
      }

      if (config.method === "POST" || config.method === "post") {
        headers["Content-Type"] = "application/json";
        // post请求,且非formData请求(即上传文档)做加密处理
        if (config.data) {
          if (config.data instanceof FormData) {
            headers["Content-Type"] = "multipart/form-data";
          } else {
            // 加密
            headers[confusionHeaderKey] = confusionHeaderValue;
            config.data = { data: aesEncrypt(JSON.stringify(config.data)) };
          }
        }
      }

      const sessionId = Cookies.get(sessionIdKey);
      if (sessionId) {
        headers.Authorization = `Bearer ${sessionId}`;
      }

      return { ...config, url, headers };
    },
  ],

  // 响应拦截器
  responseInterceptors: [
    (response) => {
      if (response?.status !== 200) {
        message.error("请求失败！");
        return response;
      }

      if (response.config.responseType == "blob") {
        // 下载文件
        const contentDisposition = response.headers["content-disposition"];
        const filename = contentDisposition?.split("filename=").at(-1)?.trim() || "unnamed-file";
        const href = URL.createObjectURL(response.data as Blob);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        return response;
      }

      const { data: bizResp } = response;
      const { data, code, msg } = bizResp as ApiResp<any>;

      if (code && code !== 200) {
        const error: any = new Error(msg);
        error.name = "BizError";
        error.info = { code, msg, data };
        throw error; // 抛出自制的错误
      }

      let responseData = data;
      // 判断是否需要解密报文
      if (CheckHeader(response.headers) && data !== undefined) {
        const decryptedData = aesDecrypt(data);
        responseData = JSON.parse(decryptedData);
        (bizResp as ApiResp<any>).data = responseData;
        response.data = bizResp;
      }

      const sessionId = responseData?.sessionId;
      if (sessionId) {
        Cookies.set(sessionIdKey, sessionId, { expires: 30 });
      }

      return response;
    },
  ],
};
