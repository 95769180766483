// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/builds/blidz-marketplace/web/src/models/companyModel';
import model_2 from '/builds/blidz-marketplace/web/src/models/siteMessageModel';
import model_3 from '/builds/blidz-marketplace/web/src/.umi-production/plugin-initialState/@@initialState';

export const models = {
model_1: { namespace: 'companyModel', model: model_1 },
model_2: { namespace: 'siteMessageModel', model: model_2 },
model_3: { namespace: '@@initialState', model: model_3 },
} as const
