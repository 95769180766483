import { unreadSiteMessagesCount } from "@/services/notification/api";
import { useState } from "react";

export default function () {
  const [msgCount, setMsgCount] = useState<number>(0);

  const refreshUnreadCount = async (userId: string) => {
    try {
      const resp = await unreadSiteMessagesCount({
        userId: userId,
      });

      if (resp.data) {
        setMsgCount(resp.data.unreadCount);
      } else {
        return;
      }
    } catch {
      return;
    }
  };

  return {
    msgCount,
    refreshUnreadCount,
  };
}
