export type ApiResp<T> = {
  code: number;
  msg?: string;
  success?: boolean;
  data?: T;
};

// 错误处理方案： 错误类型
export enum ErrorShowType {
  SILENT = 0,
  WARN_MESSAGE = 1,
  ERROR_MESSAGE = 2,
  NOTIFICATION = 3,
  REDIRECT = 9,
}
