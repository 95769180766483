import { HomeOutlined } from "@ant-design/icons";
import { useEmotionCss } from "@ant-design/use-emotion-css";
import { SelectLang, useModel } from "@umijs/max";
import { Space } from "antd";
import React from "react";
import Avatar from "./AvatarDropdown";
import SiteMessageBtn from "./SiteMessage";

export type SiderTheme = "light" | "dark";

const Store: React.FC = () => {
  const { initialState } = useModel("@@initialState");
  if (!initialState?.currentStore?.name) {
    return null;
  }
  return (
    <Space>
      <HomeOutlined />
      <div>{initialState.currentStore.name}</div>
    </Space>
  );
};

const GlobalHeaderRight: React.FC = () => {
  const className = useEmotionCss(() => {
    return {
      display: "flex",
      height: "48px",
      marginLeft: "auto",
      overflow: "hidden",
      gap: 8,
    };
  });

  const actionClassName = useEmotionCss(({ token }) => {
    return {
      display: "flex",
      float: "right",
      height: "48px",
      marginLeft: "auto",
      overflow: "hidden",
      cursor: "pointer",
      padding: "0 12px",
      borderRadius: token.borderRadius,
      "&:hover": {
        backgroundColor: token.colorBgTextHover,
      },
    };
  });

  const { initialState } = useModel("@@initialState");

  if (!initialState || !initialState.settings) {
    return null;
  }

  return (
    <Space>
      <Store />
      <div className={className}>
        <SiteMessageBtn />
        <Avatar />
        <SelectLang className={actionClassName} />
      </div>
    </Space>
  );
};
export default GlobalHeaderRight;
