import { TimeMinute } from "@/constants";
import NoFoundPage from "@/pages/404";
import { useI18nTag } from "@/utils/i18n";
import useInterval from "@/utils/useInterval";
import { history, useModel } from "@@/exports";
import { MailOutlined } from "@ant-design/icons";
import { useEmotionCss } from "@ant-design/use-emotion-css";
import { Badge, Button } from "antd";
import React from "react";

const SiteMessageBtn: React.FC = () => {
  const i = useI18nTag();
  const { msgCount, refreshUnreadCount } = useModel("siteMessageModel");
  let userId = "";

  useInterval(() => {
    refreshUnreadCount(userId);
  }, TimeMinute * 5);

  const nameClassName = useEmotionCss(({ token }) => {
    return {
      width: "120px",
      height: "48px",
      overflow: "hidden",
      lineHeight: "40px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      [`@media only screen and (max-width: ${token.screenMD}px)`]: {
        display: "none",
      },
    };
  });

  const { initialState } = useModel("@@initialState");
  const { currentUser } = initialState || {};
  if (!initialState) {
    return <NoFoundPage />;
  } else {
    userId = currentUser?.userId || "";
  }

  return (
    <Badge count={msgCount} overflowCount={99} offset={[-100, 15]}>
      <Button
        type="text"
        icon={<MailOutlined />}
        onClick={async () => {
          history.push(`/notification/site_message`);
        }}
        className={nameClassName}
      >
        {i`menu.site-message`}
      </Button>
    </Badge>
  );
};

export default SiteMessageBtn;
